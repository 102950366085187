import { useAuth } from "carmine-auth-vue"
import { useNotification } from "carmine-ui/composables"
import { useLocalStorage } from "@vueuse/core"
import moment from "moment"

export function useToken() {
    const auth = useAuth()
    const notify = useNotification()
    const router = useRouter()

    const isTokenRefreshing = useLocalStorage<boolean>('is_token_refreshing', false)
    /**
 * Check or refresh within thirty minutes before expiry or after (if RToken available)
 */
    async function checkTokenValidity() {
        let isTokenValid = false
        const expirationUnix = auth.userAuth.value.expirationUnix
        if (expirationUnix) {
            // Moment to indicate refresh token when current time surpasses
            const thresholdMoment = moment.unix(expirationUnix).subtract(30, 'minutes')
            const isTokenExpired = moment().isAfter(moment.unix(expirationUnix))
            if ((moment().isAfter(thresholdMoment) || isTokenExpired) && !isTokenRefreshing.value) {
                // We are within the threshold, refresh token
                try {
                    isTokenRefreshing.value = true
                    await auth.refreshToken()
                    isTokenValid = true
                } catch (error) {
                    // No refresh token found, logout then reroute back to login
                    notify.warning({
                        title: "Token Refresh Failed",
                        description: "Refresh token is not available. Please re-login to continue."
                    })
                    await auth.logout()
                    await router.replace({
                        path: '/login'
                    })
                    isTokenValid = false
                } finally {
                    isTokenRefreshing.value = false
                }
            }
        }
        return isTokenValid
    }

    function resetTokenStates() {
        if (isTokenRefreshing.value) {
            isTokenRefreshing.value = false
        }
    }

    return {
        checkTokenValidity,
        resetTokenStates
    }
}