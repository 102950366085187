import {
    createHttpLink,
    ApolloClient,
    InMemoryCache,
    split,
    ApolloLink,
} from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import * as Sentry from "@sentry/vue"
import { verifyToken } from "carmine-auth-vue";

const headers = () => {
    // Remove admin-secret & adds authorization header. Uncomment code below to enable this
    const auth = localStorage.getItem('auth')
    if (auth) {
        const lsAuth = JSON.parse(auth)
        // Check for token validity, valid if expirationUnix is more than current Unix
        const isTokenValid = verifyToken()
        if (lsAuth['token'] && lsAuth.token.length > 0 && isTokenValid) {
            return {
                Authorization: 'Bearer ' + lsAuth.token,
                'Content-Type': 'application/json'
            }
        }
    }

    return {
        // 'x-hasura-admin-secret': import.meta.env.VUE_APP_HASURA_SECRET,
        'x-hasura-role': 'anonymous',
        'Content-Type': 'application/json'
    }
}

const authLink = setContext(async (_) => {
    return {
        headers: await headers(),
    };
});

const httpLink = createHttpLink({
    uri: import.meta.env.VUE_APP_HASURA_URL,
    credentials: "same-origin"
});

/**
 * GraphQL Web-socket Link
 */
const wsLink = new GraphQLWsLink(
    createClient({
        url: import.meta.env.VUE_APP_HASURA_WS_URL,
        connectionParams: () => ({
            headers: headers(),
            lazy: true,
        }),
    })
);

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const scope = new Sentry.Scope()
    scope.setLevel('error')
    scope.setExtra('query', operation.query.loc?.source.body)
    scope.setExtra('variables', operation.variables)
    scope.setExtra('context', operation.getContext())
    if (graphQLErrors) {
        scope.setExtra('error', JSON.stringify(graphQLErrors))
        Sentry.captureMessage(`[ErrorLink] GraphQL Error : ${graphQLErrors.map(error => error.message).join(', ')}`, scope)
    }
    if (networkError) {
        scope.setExtra('error', JSON.stringify(networkError))
        Sentry.captureMessage(`[ErrorLink] Network Error : ${networkError.message}`, scope)
    };
});

const link: ApolloLink = ApolloLink.from([
    authLink,
    errorLink,
    split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
            );
        },
        wsLink,
        httpLink
    ) as any
])

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
    link,
    cache,
});
