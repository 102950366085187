<script setup lang="ts">
import Notification from '~/components/Notification.vue'

// Composables
import { useAbility } from '@casl/vue'
import { registerSwiper } from 'carmine-ui/components';
import { useNotification } from 'carmine-ui/composables';
import { useAuth } from 'carmine-auth-vue'
import { useGetUserLastLoginAtSubscription, useGetAclModulePermissionsForUserIdQuery } from './graphql/types';
import { useNotification as useN, symbol } from '~/composables/notification'
import { useDatabaseTypes } from './pinia/database'

// Helpers
import moment from 'moment'
import IdleJS from 'idle-js'
import _ from 'lodash'
import * as Sentry from '@sentry/vue'

// Types 
import type { SubjectRawRule, ExtractSubjectType, Subject, MongoQuery } from '@casl/ability'

registerSwiper()

const { action, data, isOpen } = useN()
provide(symbol, action.open)

// Pinia states
const databaseTypes = useDatabaseTypes()
const auth = useAuth()
const ability = useAbility()
const notify = useNotification()
const router = useRouter()
const { checkTokenValidity, resetTokenStates } = useToken()

const {
    result: userLastLoginAtResult
} = useGetUserLastLoginAtSubscription(() => ({
    id: auth.hasuraUser.value.id
}), () => ({
    enabled: auth.isAuthenticated.value
}))

watch(userLastLoginAtResult, async () => {
    const result = userLastLoginAtResult.value
    if (result && result.user && auth.isAuthenticated.value) {
        const { lastLoginAt } = result.user
        const tokenLastLoginAt = auth.hasuraUser.value.lastLoginAt
        // If new lastLoginAt differs, meaning there is a new login, hence logout and notify
        if (!moment(lastLoginAt).isSame(moment(tokenLastLoginAt))) {
            notify.warning({
                title: "Session Expired",
                description: "Another login has been detected on another session. Your session will continue there."
            })
            auth.logout()
            router.replace('/login')
        }
    }
})

// Set Sentry User 
watchEffect(() => {
    if (auth.isAuthenticated.value) {
        const user = auth.hasuraUser.value
        Sentry.configureScope((scope) => {
            scope.setUser({
                id: user.id,
                username: user.name,
                email: user.email,
            })
        })
    } else {
        Sentry.setUser(null)
        // Remove all rules
        ability.update([])
    }
})

/* GET MODULE PERMISSIONS */
const { onResult: onAclModulePermissionsResult } = useGetAclModulePermissionsForUserIdQuery(() => ({
    id: auth.hasuraUser.value.id
}), () => ({
    pollInterval: moment.duration(10, 'seconds').asMilliseconds(),
    enabled: auth.isAuthenticated.value,
    fetchPolicy: 'network-only'
}))

onAclModulePermissionsResult((param) => {
    const newPermissions = param.data
    // Update abilities
    if (newPermissions && newPermissions.user) {
        if (!newPermissions.user.aclRole.backendOnly) {
            // Rule JSON
            const rules: SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery>[] = []
            const auth0Id = auth.hasuraUser.value.auth0Id
            const modulePermissions = newPermissions.user.aclRole.modulePermissions

            for (const permission of modulePermissions) {
                if (permission.module) {
                    const hasFullAccess = permission.createPermission && permission.readPermission && permission.updatePermission && permission.deletePermission
                    if (hasFullAccess) {
                        rules.push({
                            action: 'manage',
                            subject: permission.module.name
                        })
                    } else {
                        if (permission.createPermission) {
                            const createRule: SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery> = {
                                subject: permission.module.name,
                                action: 'create'
                            }
                            rules.push(createRule)
                        }
                        if (permission.readPermission || permission.readOwnPermission) {
                            const selectRule: SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery> = {
                                subject: permission.module.name,
                                action: 'select',
                                ...(permission.readOwnPermission && !permission.readPermission) && {
                                    conditions: {
                                        "createdBy.auth0Id": auth0Id
                                    },
                                    graphQLFilter: {
                                        createdBy: {
                                            auth0Id: {
                                                _eq: auth0Id
                                            }
                                        }
                                    }
                                }
                            }
                            rules.push(selectRule)
                        }
                        if (permission.updatePermission || permission.updateOwnPermission) {
                            const updateRule: SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery> = {
                                subject: permission.module.name,
                                action: 'update',
                                ...(permission.updateOwnPermission && !permission.updatePermission) && {
                                    conditions: {
                                        "createdBy.auth0Id": auth0Id
                                    }
                                }
                            }
                            rules.push(updateRule)
                        }
                        if (permission.deletePermission || permission.deleteOwnPermission) {
                            const deleteRule: SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery> = {
                                subject: permission.module.name,
                                action: 'delete',
                                ...(permission.deleteOwnPermission && !permission.deletePermission) && {
                                    conditions: {
                                        "createdBy.auth0Id": auth0Id
                                    }
                                }
                            }
                            rules.push(deleteRule)
                        }
                    }
                }
            }
            ability.update(rules)
        } else {
            ability.update([{
                action: 'manage',
                subject: 'all'
            }])
        }
    }
})

/* GET MODULE PERMISSIONS */

onMounted(() => {
    resetTokenStates()
    const idle = new IdleJS({
        idle: moment.duration(10, 'seconds').asMilliseconds(),
        onActive: async () => {
            await checkTokenValidity()
        }
    })
    idle.start()
    window.setInterval(checkTokenValidity, moment.duration(2, 'minutes').asMilliseconds())
})
</script>

<template>
    <template v-if="databaseTypes.isLoading">
        Loading
    </template>
    <template v-else>
        <Notification :show="isOpen" :dismiss="action.dismiss" :state="data.state" :title="data.title"
            :description="data.description">
            <!-- <template #cta>
                                            <span v-html="data.cta">

                                            </span>
                                        </template> -->
        </Notification>
        <router-view />
    </template>
</template>

<style>
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

label {
    cursor: pointer;
}


.file-uploads {
    @apply !text-start w-full overflow-visible;
}

.file-uploads.file-uploads-html5 label {
    @apply cursor-pointer
}
</style>
