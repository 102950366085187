import type { NavigationObject } from "~/types/navigation";
import type { MongoAbility } from "@casl/ability"

// Icons
import FileCheckIcon from "~/assets/icons/file-check-02.svg";
import SettingsIcon from "~/assets/icons/settings-01.svg";
import SpeedometerIcon from "~/assets/icons/speedometer-01.svg";
import CalendarIcon from "~/assets/icons/calendar-check-02.svg";
import UserIcon from "~/assets/icons/user-03.svg";
import TableIcon from "~/assets/icons/table.svg";

export const navlinks: NavigationObject[] = [
    {
        name: "Dashboard",
        path: "/dashboard",
        icon: TableIcon,
        moduleName: "Dashboard"
    },
    {
        name: "Bookings",
        path: "#",
        icon: CalendarIcon,
        children: [
            {
                name: "Order Management",
                path: "/manage/orders",
                moduleName: "Order Management",
            },
            {
                name: "Test Drive Management",
                path: "/manage/test-drives",
                moduleName: "Test Drive Management",
            },
            {
                name: "Inspection Booking",
                path: "/manage/inspection-booking",
                moduleName: "Inspection Booking",
            },
            {
                name: "Booking Calendar",
                path: "/booking-calendar",
                moduleName: "Inspection Booking",
            },
        ],
    },
    {
        name: "Vehicles",
        path: "#",
        icon: SpeedometerIcon,
        children: [
            {
                name: "Retail Management",
                path: "/manage/vehicles",
                moduleName: "Retail Management",
            },
            {
                name: "Bidding Management",
                path: "/manage/biddings",
                moduleName: "Bidding Management",
            },
            {
                name: "Vehicle Recommendations",
                path: "/manage/recommendations",
                moduleName: "Vehicle Recommendations",
            },
        ],
    },
    {
        name: "Admin",
        path: "#",
        icon: UserIcon,
        children: [
            {
                name: "Audit Trail",
                path: "/audit-trail",
                moduleName: "Audit Trail",
            },
            {
                name: "User Management",
                path: "/manage/users",
                moduleName: "User Management",
            },
            {
                name: "Role Management",
                path: "/manage/roles",
                moduleName: "Role Management",
            },
            {
                name: "Customer Management",
                path: "/manage/customers",
                moduleName: "Customer Management",
            },
            {
                name: "Contact Us Management",
                path: "/manage/contact-us",
                moduleName: "Contact Us Management",
            },
            {
                name: "Broker Management",
                path: "/manage/brokers",
                moduleName: "Broker Management",
            },
            {
                name: "Dealer Management",
                path: "/manage/dealers",
                moduleName: "Dealer Management",
            },
        ],
    },
    {
        name: "Inspections",
        path: "/manage/inspections",
        icon: FileCheckIcon,
        moduleName: "Inspection Management",
    },
];

export function filterNavlinksByAbility(ability: MongoAbility) {
    const links: NavigationObject[] = []
    navlinks.forEach((link) => {
        if (link.children) {
            const children: NavigationObject[] = []
            link.children.forEach((child) => {
                if (!child.moduleName) {
                    children.push(child)
                } else {
                    if (ability.can('select', child.moduleName) || ability.can('create', child.moduleName)) {
                        children.push(child)
                    }
                }
            })
            if (children.length > 0) {
                links.push({
                    ...link,
                    children,
                })
            }
        } else {
            if (!link.moduleName) {
                links.push(link)
            } else {
                if (ability.can('select', link.moduleName) || ability.can('create', link.moduleName)) {
                    links.push(link)
                }
            }
        }
    })
    return links
}

export const accountLinks: NavigationObject[] = [
    {
        name: "Settings",
        path: "/settings",
        icon: SettingsIcon,
    },
];
