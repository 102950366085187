/**
 * Enum for settings tab route query parameters.
 */
export enum SettingsTabsQuery {
    Branches = "branches",
    TestDriveSlots = "test-drive-slots",
    InspectionSlots = "inspection-slots",
    Models = "models",
    BiddingSchedules = "bidding-schedules",
    Vouchers = "vouchers",
}

/**
 * Maps settings tab query parameters to database ACL module names.
 */
export const settingsModuleNames: { [key in SettingsTabsQuery]: string } = {
    [SettingsTabsQuery.Branches]: 'Settings - Branch Management',
    [SettingsTabsQuery.TestDriveSlots]: 'Settings - Test Drive Time Slots',
    [SettingsTabsQuery.InspectionSlots]: 'Settings - Inspection Time Slots',
    [SettingsTabsQuery.Models]: 'Settings - Vehicle Model',
    [SettingsTabsQuery.BiddingSchedules]: 'Settings - Bidding Sessions',
    [SettingsTabsQuery.Vouchers]: 'Settings - Voucher Management',
};

/**
 * Fetches the database ACL module name for a given route query parameter.
 * By converting route query parameters like "branches" into specific ACL module names,
 * such as 'Settings - Branch Management', it facilitates access control verification
 * directly against the database's defined permissions.
 *
 * @param {string} routeQueryTab - The route query parameter to be translated into an ACL module name.
 * @return {string} The corresponding ACL module name for access control checks.
 */
export const getSettingsModuleName = (routeQueryTab: SettingsTabsQuery) => {
    return settingsModuleNames[routeQueryTab];
}

