<script setup lang="ts">
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription,
} from "@headlessui/vue";
import SuccessIcon from '~/assets/icons/success.svg'
import ErrorIcon from '~/assets/icons/warning.svg'
import CloseIcon from '~/assets/icons/x-close.svg'
import SVGIcon from '~/components/SVGIcon.vue'

interface Props {
    show: boolean,
    dismiss: any,
    state?: 'success' | 'error',
    title: string,
    description: string
}

const { show, dismiss, state = 'success', title, description } = defineProps<Props>();

const iconMap = {
    success: SuccessIcon,
    error: ErrorIcon,
}

const dialogPanelStyle = {
    success: 'ring-success-300 bg-success-25',
    error: 'ring-error-300 bg-error-25'
}

const dialogIconStyle = {
    success: 'stroke-success-600',
    error: 'stroke-error-600'
}

const dialogTitleStyle = {
    success: 'text-success-700',
    error: 'text-error-700'
}

const dialogDescStyle = {
    success: 'text-success-600',
    error: 'text-error-600'
}

</script>

<template>
    <TransitionRoot appear :show="show" as="template">
        <Dialog as="div" @close="dismiss" class="relative z-50">
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
                leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-black bg-opacity-0" />
            </TransitionChild>

            <div class="fixed inset-x-0 bottom-0 top-24 overflow-y-auto">
                <div class="flex min-h-full items-start justify-center p-4 text-center">
                    <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95">
                        <DialogPanel :class="dialogPanelStyle[state]"
                            class="w-full max-w-lg desktop:max-w-3xl ring-1 transform overflow-hidden rounded-lg shadow-sm p-4 text-left align-middle transition-all">
                            <div class="flex flex-col tablet:flex-row self-stretch items-start">
                                <div class="flex flex-row justify-between tablet:hidden self-stretch mb-4">
                                    <SVGIcon :src="iconMap[state]" :class="dialogIconStyle[state]"
                                        class="stroke-[1.67px]" :width="20" :height="20" />
                                    <button type="button" @click="dismiss">
                                        <SVGIcon :src="CloseIcon" :class="dialogIconStyle[state]"
                                            class="stroke-[1.67px]" />
                                    </button>

                                </div>
                                <SVGIcon :src="iconMap[state]" :class="dialogIconStyle[state]"
                                    class="stroke-[1.67px] hidden tablet:inline" :width="20" :height="20" />
                                <div class="tablet:mx-4 flex-grow">
                                    <DialogTitle as="h3" class="text-sm font-medium" :class="dialogTitleStyle[state]">
                                        {{ title }}
                                    </DialogTitle>
                                    <div class="mt-2" :class="dialogDescStyle[state]">
                                        <p class="text-sm">
                                            {{ description }}
                                        </p>
                                    </div>
                                    <div>
                                        <slot name="cta" class="mt-3"></slot>
                                    </div>
                                </div>
                                <button type="button" @click="dismiss">
                                    <SVGIcon :src="CloseIcon" :class="dialogIconStyle[state]"
                                        class="stroke-[1.67px] hidden tablet:inline" />
                                </button>
                            </div>

                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
