import { createApp } from "vue";
import { createPinia } from "pinia";
import CarmineAuth from "carmine-auth-vue";
import { CUIPlugin } from "carmine-ui/composables";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import vueSvg from "vue-svg-inline-plugin";
import VueTransition from "@morev/vue-transitions";
import VueStorage from "vue3-storage";
import VueScrollTo from "vue-scrollto";
import VCalendar from "v-calendar";
import "v-calendar/style.css";
import "carmine-ui/css";

// CASL
import { abilitiesPlugin } from "@casl/vue"
import ability from "~/helpers/abilities";

// Apollo
import {
    DefaultApolloClient,
    provideApolloClient,
} from "@vue/apollo-composable";
import { apolloClient } from "./helpers/apollo";
import { createApolloProvider } from "@vue/apollo-option";

// Global components
import ContentContainerVue from "~/layouts/ContentContainer.vue";
import VueUploadComponent from "vue-upload-component";
import Divider from "~/components/Divider.vue";

// Sentry
import * as Sentry from "@sentry/vue";
import { HttpClient } from "@sentry/integrations";

import App from "./App.vue";
import router from "./router";
import "./index.css";

// Options API for Apollo
provideApolloClient(apolloClient);
const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
});

const app = createApp(App);
const pinia = createPinia();

app.provide(DefaultApolloClient, apolloClient);
app.use(apolloProvider);
app.use(pinia);
app.use(router);
app.use(CarmineAuth, {
    baseURL: import.meta.env.VUE_APP_AUTH_SERVER_BASE_URL,
});
app.use(CUIPlugin, {
    emailBaseUrl: import.meta.env.VUE_APP_EMAIL_SERVER_BASE_URL,
    imageKitUrl: import.meta.env.VUE_APP_IMAGEKIT_URL,
    router,
});
app.use(autoAnimatePlugin);
app.use(vueSvg);
app.use(VueTransition);
app.use(VueStorage, {});
app.use(VueScrollTo);
app.use(VCalendar, {});

// CASL Setup
app.use(abilitiesPlugin, ability, {
    useGlobalProperties: true,
});

// Sentry Setup
Sentry.init({
    app,
    environment: import.meta.env.MODE,
    dsn: import.meta.env.VUE_APP_SENTRY_DSN,
    enabled: import.meta.env.MODE !== 'development',
    // debug: true,
    tracePropagationTargets: [
        "localhost",
        "admin.carmine.my",
        "dev-admin.carmine.my",
        "admin-dev-dot-carmine-my.et.r.appspot.com",
    ],
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            routingInstrumentation: Sentry.vueRouterInstrumentation(router, {
                routeLabel: "path",
            }),
        }),
        new Sentry.Replay({
            maskAllText: false,
            maskAllInputs: false,
            mask: ['[type="password"]'],
        }),
        new HttpClient(),
    ],
    sendDefaultPii: true,
    // Tracks child components
    trackComponents: true,
    // Performance Monitoring
    tracesSampleRate: 0.7, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.component("content-container", ContentContainerVue);
app.component("file-upload", VueUploadComponent);
app.component("divider", Divider);
app.mount("#app");
