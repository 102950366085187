import { ref, inject } from "vue";
import type {
    NotificationData,
    NotificationAction,
} from "~/types/notification";

export function useNotification() {
    const isOpen = ref(false);
    const data = ref<NotificationData>({
        title: "Test",
        description: "Desc Test",
        state: "success",
    });
    const open = (dataObj: NotificationData) => {
        data.value.title = dataObj.title;
        data.value.description = dataObj.description;
        data.value.state = dataObj.state;
        nextTick(() => {
            isOpen.value = true;
        });
    };
    const action: NotificationAction = {
        open,
        dismiss: () => {
            isOpen.value = false;
        },
    };

    const notify: (arg0: NotificationData) => void = inject(symbol, () => {
        console.log("Problem with noti injection");
    });

    return { isOpen, data, action, notify };
}

export const symbol = Symbol("notification");
